<template>
  <div :style="inputContainerStyle">
    <div :class="{ 'input-container': true, 'status-error': errorFlag, dark: $vuetify.theme.dark }">
      <input
        ref="input"
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        :maxlength="maxlength"
        :class="themeName"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
    <label v-if="label" :class="themeName">{{ label }}</label>
    <div v-if="errorFlag" class="status-error-text">{{ errorMessage }}</div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  /**
   * emits
   * icon
   * focus
   * enter
   */
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    transform: {
      type: Function,
      default: (v) => v,
    },
    width: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      errorFlag: false,
      errorMessage: '',
    }
  },
  computed: {
    inputContainerStyle() {
      return this.width ? `position:relative;width: ${this.width}` : 'position:relative;'
    },
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
  },
  methods: {
    onBlur() {
      if (!this.errorFlag) {
        this.$refs.input.style.border = 'none'
      }
    },
    onFocus() {
      if (!this.errorFlag) {
        this.$refs.input.style.border = '1px solid var(--f-primary-90)'
        this.$refs.input.style.borderRadius = '8px'
      }
    },
    validate(text, justCheck) {
      let errorCheck = false
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            if (justCheck) {
              errorCheck = true
            } else {
              this.onBlur()
              this.errorFlag = true
              this.errorMessage = error
            }
            break
          } else {
            if (!justCheck) {
              this.errorFlag = false
              this.errorMessage = ''
            }
          }
        }
      }
      return justCheck ? !errorCheck : !this.errorFlag
    },
    onInput(e) {
      if (this.text && this.text.length === this.maxlength) {
        // this.$log.debug('past maxlength', this.text.length, this.maxlength)
      } else {
        // this.$log.debug('within maxlength', this.text.length, this.maxlength)
        this.text = e.target.value
        this.text = this.transform(this.text)
        e.target.value = this.text
        this.validate()
        this.onFocus()
        this.$emit('input', this.text)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  &.status-error {
    border: 1px solid var(--f-primary-100);
  }
}

label {
  position: absolute;
  top: -27px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

input {
  flex-grow: 1;
  padding: 0 16px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  color: var(--f-text-black);
  &::placeholder {
    color: var(--f-text-black-middle);
  }
  &.dark {
    color: var(--f-text-white-high);
    &::placeholder {
      color: var(--f-text-white-middle);
    }
  }
}

.status-error-text {
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: -19px;
  right: 0;
  color: var(--f-primary-100);
}
</style>
