<template>
  <v-menu :value="open" @input="$emit('openDropdown')" offset-y nudge-bottom="7px">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ 'filter-container': true, dark: $vuetify.theme.dark, 'error-status': error }">
        <span v-if="!!value" class="text-truncate" style="margin-left: 16px">{{ value }}</span>
        <span v-else :class="{ placeholder: true, dark: $vuetify.theme.dark, 'error-status': error }" style="margin-left: 16px">{{
          placeholder
        }}</span>
        <v-spacer></v-spacer>
        <img v-if="error && open" src="@/assets/Caret-Up_Filled_16px_Icon-red.svg" width="16px" />
        <img v-else-if="error" src="@/assets/Caret-Down_Filled_16px_Icon-red.svg" width="16px" />
        <img v-else-if="open" src="@/assets/Caret-Up_Filled_16px_Grey_Icon.svg" width="16px" />
        <img v-else src="@/assets/Caret-Down_Filled_16px_Grey_Icon.svg" width="16px" />
        <div v-if="error" class="error-text">Please select this field.</div>
      </div>
    </template>
    <div v-if="open" :class="`item-container ${themeName}`">
      <div class="wrapper">
        <div v-for="(item, index) in items" :key="index" @click="onClickItem(item)" :class="`item ${themeName}`">
          {{ item }}
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: String,
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // open: false,
      error: false,
    }
  },
  methods: {
    validate() {
      this.error = !this.value
      return !this.error
    },
    onClickItem(item) {
      this.error = false
      this.$emit('input', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }

  .placeholder {
    color: var(--f-text-black-middle);
    &.dark {
      color: var(--f-text-white-middle);
    }

    &.error-status {
      color: var(--f-primary-90);
    }
  }

  &.error-status {
    border: 1px solid var(--f-primary-100);
  }

  img {
    margin-right: 16px;
  }

  .error-text {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    top: -19px;
    right: 0;
    color: var(--f-primary-100);
  }
}

.item-container {
  max-height: 200px;
  border-radius: 10px;
  padding: 8px 0;
  overflow: hidden;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(200px - 16px);
    overflow: auto;
  }

  .item {
    display: flex;
    align-items: center;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
    padding: 0 16px;
    cursor: pointer;
    color: var(--f-text-black);
    &.dark {
      color: var(--f-text-white-high);
    }

    &:hover {
      background: var(--f-primary-50);
      color: var(--f-text-white);
      &.dark {
        color: var(--f-text-white-high);
      }
    }
  }
}
</style>
