<template>
  <div style="position: relative; width: 100%">
    <div
      :class="{ 'file-attachment': true, dark: $vuetify.theme.dark }"
      @click.self="onClickFileAttachment"
      @drop.prevent="onDropHandler"
      @dragover.prevent=""
    >
      <input @input="onInputFile" ref="file" type="file" accept=".png,.jpeg,.jpg,.zip" hidden :multiple="maxFiles > 1" />
      <FileChip v-for="file of files" :key="file.name" :file="file" @click="onClickClose(file)"></FileChip>
    </div>
    <div :class="`file-attachment-description ${themeName}`">
      * You can only upload single file, maximum 30MB. File type should be jpeg, jpg, png, zip.<br />In case of video file, please compress it in a
      .zip file format.
    </div>
    <!-- <div v-if="error" class="file-attachment-error">Please check the attached file type or size.</div> -->
    <SupportFileUploadFailedDialog
      :value="showSupportFileUploadFailedDialog"
      @hide="showSupportFileUploadFailedDialog = false"
    ></SupportFileUploadFailedDialog>
  </div>
</template>

<script>
import FileChip from './FileChip'
import SupportFileUploadFailedDialog from '@components/desktop/dialog/SupportFileUploadFailedDialog.vue'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { FileChip, SupportFileUploadFailedDialog },
  props: {
    maxSize: {
      type: Number,
      default: 30 * 1024 * 1024, // 30MB
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      files: [],
      error: false,
      showSupportFileUploadFailedDialog: false,
      acceptedFileTypes: ['image/png', 'image/jpeg', 'application/zip', 'application/x-zip-compressed'],
      acceptedExtensions: ['png', 'jpeg', 'jpg', 'zip'],
    }
  },
  methods: {
    validate() {
      this.error = false
      let limitFiles = this.files
      if (this.files.length > this.maxFiles) {
        this.error = true
      }
      const filterdFiles = limitFiles.filter((file) => file.size <= this.maxSize)
      if (limitFiles.length !== filterdFiles.length) {
        this.error = true
      }
      return !this.error
    },
    onClickFileAttachment() {
      this.$refs.file.click()
    },
    onDropHandler(e) {
      const items = e.dataTransfer.items
      const files = e.dataTransfer.files
      if (items) {
        this.$log.debug('FileAttachment#onDropHandler items', items)
        let dropFiles = []
        for (var i = 0; i < items.length; i++) {
          if (items[i].kind === 'file') {
            const file = items[i].getAsFile()
            dropFiles.push(file)
          }
        }
        if (dropFiles.length) {
          this.onInputFile({ target: { files: dropFiles } })
        }
      } else if (files) {
        this.$log.debug('FileAttachment#onDropHandler files', files)
        this.onInputFile({ target: { files } })
      }
    },
    onInputFile(e) {
      this.files = []
      this.error = false
      const files = Array.from(e.target.files)
      this.$log.debug('files', files[0])
      if (
        files.length &&
        (files[0]?.size > this.maxSize ||
          !this.acceptedFileTypes.includes(files[0]?.type) ||
          !this.acceptedExtensions.includes(files[0]?.name.split('.').pop()))
      ) {
        this.showSupportFileUploadFailedDialog = true
      } else {
        let limitFiles = files
        if (files.length > this.maxFiles) {
          this.error = true
          limitFiles = files.slice(0, this.maxFiles)
        }
        const filterdFiles = limitFiles.filter((file) => file.size <= this.maxSize)
        this.$log.debug('FileAttachment#onInputFile filterdFiles', filterdFiles)
        if (limitFiles.length !== filterdFiles.length) {
          this.error = true
        }
        this.files = filterdFiles
        this.$emit('input', this.files)
      }
    },
    onClickClose(file) {
      const index = this.files.findIndex((f) => f === file)
      if (index >= 0) {
        this.files.splice(index, 1)
      }
      if (!this.files.length) {
        this.$refs.file.value = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-attachment {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  min-height: 44px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  &.status-error {
    border: 1px solid var(--f-primary-100);
  }
}

.file-attachment-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 7px;
  color: var(--f-text-black-middle);
  &.dark {
    color: var(--f-text-white-middle);
  }
}

.file-attachment-error {
  position: absolute;
  top: -19px;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--f-primary-100);
}
</style>
