<template>
  <div :class="`file-chip ${themeName}`">
    <img v-if="type === 'image'" src="@/assets/Image_Filled_16px_Icon.svg" width="16px" style="margin-right: 5px" />
    <img v-else-if="type === 'video'" src="@/assets/eva_video-fill.svg" width="16px" style="margin-right: 5px" />
    <img v-else-if="type === 'zip'" src="@/assets/Zip_Filled_16px_Icon.svg" width="16px" style="margin-right: 5px" />
    <img v-else src="@/assets/File_Filled_16px_Icon.svg" width="16px" style="margin-right: 5px" />
    <span class="text-truncate">{{ file.name }}</span>
    <img v-if="$vuetify.theme.dark" @click.stop="$emit('click')" src="@/assets/Clear_Filled_12px_Icon.svg" width="12px" class="close" />
    <img @click.stop="$emit('click')" src="@/assets/Clear_Filled_12px_Icon-black.svg" width="12px" class="close" />
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    file: File,
  },
  computed: {
    type() {
      if (this.file) {
        switch (this.file.type) {
          case 'video/mp4':
          case 'video/avi':
          case 'video/quicktime':
            return 'video'
          case 'image/png':
          case 'image/jpeg':
            return 'image'
          case 'application/zip':
            return 'zip'
          case 'application/x-zip-compressed':
            return 'zip'
        }
      }
      return 'unknown'
    },
  },
}
</script>

<style lang="scss" scoped>
.file-chip {
  display: flex;
  align-items: center;
  padding: 0 10px 0 8px;
  max-width: 506px;
  height: 34px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 6px;
  color: var(--f-text-black);
  background-color: var(--f-cool-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-high);
  }

  .close {
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
