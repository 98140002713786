<template>
  <v-dialog :value="value" width="400px" persistent no-click-animation>
    <v-card :class="`save-label-dialog ${themeName}`">
      <v-card-text class="pa-0">
        <div :class="`save-label-dialog-title ${themeName}`">Can't attach this item</div>
        <div :class="`save-label-dialog-content ${themeName}`">
          <div>Please check the file size or type.</div>
        </div>
        <div class="d-flex">
          <button @click="hide" class="save-label-dialog-button ok">Okay</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
  },
  methods: {
    hide() {
      this.$emit('hide')
    },
  },
}
</script>

<style lang="scss" scoped>
.save-label-dialog {
  background-color: white !important;
  &.dark {
    background-color: var(--v-backgroundDisabledMap) !important;
  }
}

.save-label-dialog-title {
  text-align: center;
  padding: 50px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--v-textDefault-white);
  background-color: white !important;
  &.dark {
    background-color: var(--v-backgroundDisabledMap) !important;
    color: var(--f-text-white-high);
  }
}

.save-label-dialog-content {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 61px;
  color: var(--v-textDefault-white);
  background-color: white !important;
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--v-backgroundDisabledMap) !important;
  }
}

.save-label-dialog-button {
  font-weight: 600;
  font-size: 16px;
  height: 60px;
  background-color: var(--f-supplementary-modal-cancel);

  &.ok {
    flex-grow: 1;
    background-color: var(--f-supplementary-modal-blue);
    color: var(--f-text-white);
  }
}
</style>
