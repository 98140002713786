<template>
  <div style="position: relative">
    <div :class="{ 'border-line': true, focus, dark: $vuetify.theme.dark, 'error-status': errorFlag || isTrancated }" :style="`height: ${height}`">
      <textarea
        ref="input"
        :maxlength="maxlength"
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        :class="themeName"
      />
      <div v-if="this.maxlength" :class="{ 'character-count': true, dark: $vuetify.theme.dark, 'error-status': errorFlag }">
        {{ this.text.length }}/{{ this.maxlength }} Characters
      </div>
    </div>
    <div v-if="errorFlag" class="error-status-text">{{ errorMessage }}</div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Please describe your issue.\nIn case of bug report, please give details about the device/OS/web browser\nyou are using.',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxlength: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: '122px',
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      errorFlag: this.error,
      errorMessage: '',
      isTrancated: false,
    }
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
    error(val) {
      this.errorFlag = val
    },
  },
  methods: {
    validate(text) {
      this.isTrancated = false
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            this.errorFlag = true
            this.errorMessage = error
            break
          } else {
            this.errorFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.errorFlag
    },
    onInput(e) {
      this.text = e.target.value
      this.validate()
      if (this.text && this.text.length && this.maxlength && this.maxlength < this.text.length) {
        // this.text = this.text.slice(0, this.maxlength)
        e.target.value = this.text
        this.isTrancated = true
      }
      this.$emit('input', this.text)
    },
    onBlur() {
      this.focus = false
    },
    onFocus() {
      this.focus = true
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  outline: none;
  padding: 13px 16px 0;
  width: 100%;
  height: 0;
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px 8px 0 0;
  line-height: 19px;
  resize: none;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &::placeholder {
    color: var(--f-text-black-middle);
  }
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
    &::placeholder {
      color: var(--f-text-white-middle);
    }
  }
}

.error-status-text {
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: -19px;
  right: 0;
  color: var(--f-primary-100);
}

.border-line {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--f-text-black-middle);
  background-color: var(--f-light-gray-1);
  &.dark {
    color: var(--f-text-white-middle);
    background-color: var(--f-bg-middle);
  }

  &.focus {
    border: 1px solid var(--f-primary-90);
  }

  &.error-status {
    border: 1px solid var(--f-primary-100);
  }

  .character-count {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 0 10px 5px;
    border-radius: 0px 0px 8px 8px;

    &.error-status {
      color: var(--f-primary-100);
    }
  }
}
</style>
