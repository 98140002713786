<template>
  <div :class="`support ${themeName}`">
    <!-- <Navigation></Navigation> -->
    <!-- <section v-if="successSubmit">
      <Success></Success>
      <Footer></Footer>
    </section> -->
    <section @scroll="onScroll">
      <div :class="`support-title ${themeName}`">Contact our support team</div>
      <div style="align-self: center; margin-top: 30px">Are you wondering about the features?</div>
      <div style="align-self: center">
        Check out the
        <a :class="`support-text-link ${themeName}`" href="http://docs.firescout.ai/user-manual/user-manual-for-pc" target="_blank">user manual</a> or
        <a :class="`support-text-link ${themeName}`" href="http://docs.firescout.ai/faq/frequently-asked-questions" target="_blank">FAQ</a> the answer
        might be there already!
      </div>
      <div style="align-self: center; margin-top: 20px">If you don’t find what you need, ask for assistance below and we will get to work!</div>
      <div style="align-self: center">
        If you think the problem is a bug, please raise it here with full details and we will report to development.
      </div>
      <div style="align-self: center; margin-top: 20px; margin-bottom: 70px">
        We are also waiting for any
        <a
          :class="`support-text-link ${themeName}`"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdZhJPETyUP-BT0RCs3vXGenQ5OXA4pU77OY2UajgbBhf1ZsA/viewform?usp=sf_link"
          target="_blank"
          >feedbacks</a
        >
        from you about FireScout!
      </div>
      <form @submit="onSubmit">
        <div class="form-row" style="margin-bottom: 40px">
          <label for="type">Type <span class="asterisk">*</span></label>
          <DropdownButton
            ref="type"
            id="SupportDropdownButton"
            v-model="type"
            :items="typeList"
            :open="openDropdownButton"
            placeholder="Please Select"
            @openDropdown="openDropdownButton = true"
          ></DropdownButton>
        </div>
        <div class="form-row" style="margin-bottom: 40px">
          <label for="type">Description <span class="asterisk">*</span></label>
          <InputBox
            ref="description"
            v-model="description"
            :rules="rulesDescription"
            :maxlength="descriptionMaxlength"
            height="180px"
            style="width: 100%"
          ></InputBox>
        </div>
        <div class="form-row" style="margin-bottom: 67px">
          <label for="type">File Attachment</label>
          <FileAttachment ref="file" @input="files = $event"></FileAttachment>
        </div>
        <div class="form-row" style="margin-bottom: 67px">
          <label for="type">Personal Information <span class="asterisk">*</span></label>
          <Input
            ref="firstName"
            v-model="firstName"
            :rules="rulesRequired"
            label="First Name"
            placeholder="First Name"
            :maxlength="50"
            width="306px"
            style="margin-right: 10px"
          ></Input>
          <Input
            ref="lastName"
            v-model="lastName"
            :rules="rulesRequired"
            label="Last Name"
            placeholder="Last Name"
            :maxlength="20"
            style="flex-grow: 1"
          ></Input>
        </div>
        <div class="form-row" style="margin-bottom: 67px">
          <label for="type"></label>
          <Input
            ref="organization"
            v-model="organization"
            :rules="rulesRequired"
            label="Organization"
            placeholder="Organization"
            style="flex-grow: 1"
          ></Input>
        </div>
        <div class="form-row" style="margin-bottom: 50px">
          <label for="type"></label>
          <Input
            ref="email"
            v-model="email"
            :rules="rulesEmail"
            label="Email"
            placeholder="Email (youremail@email.com)"
            :maxlength="80"
            style="width: 100%"
          ></Input>
        </div>
        <div class="form-row" style="justify-content: flex-end">
          <Button type="submit" @submit="onSubmit" style="width: 150px; height: 44px">Submit</Button>
        </div>
      </form>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Navigation from '@common/Navigation'
import Footer from '@desktop/adminOrViewer/detection/Footer'
import DropdownButton from '@desktop/adminOrViewer/support/DropdownButton'
import Input from '@desktop/adminOrViewer/support/Input'
import InputBox from '@desktop/adminOrViewer/support/InputBox'
import Success from '@desktop/adminOrViewer/support/Success'
import FileAttachment from '@desktop/adminOrViewer/support/FileAttachment'
import Button from '@common/Button'
import RotuerMixin from '@/mixin/router.mixin'
import Theme from '@/mixin/theme.mixin'
import rules from '@/rules'
import { salesforce, alert } from '@/api'

export default {
  mixins: [RotuerMixin, Theme],
  components: { Footer, DropdownButton, Input, InputBox, FileAttachment, Button, Success },
  data() {
    const self = this
    return {
      formValid: false,
      typeList: ['Bug Report', 'Dashboard Feature Query', 'Account Query', 'Others'],
      rulesRequired: [(val) => !!val || 'Please fill out this field.'],
      rulesDescription: [
        (val) => !!val || 'Please fill out this field.',
        (val) => val.length <= self.descriptionMaxlength || 'Maximum characters exceeded.',
      ],
      rulesEmail: [(val) => !!val || 'Please fill out this field.', (val) => !!rules.justEmail(val) || 'Enter a valid email address.'],
      descriptionMaxlength: 3000,
      type: null,
      description: '',
      files: [],
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      openDropdownButton: false,
    }
  },
  methods: {
    onScroll() {
      if (this.openDropdownButton) {
        this.openDropdownButton = false
      }
    },
    async onSubmit(e) {
      e.preventDefault()
      this.$log.debug('Support#onSubmit')
      const validType = this.$refs.type.validate()
      const validDescription = this.$refs.description.validate()
      const validFile = this.files.length ? this.$refs.file.validate() : true
      const validFirstName = this.$refs.firstName.validate()
      const validLastName = this.$refs.lastName.validate()
      const validOrganization = this.$refs.organization.validate()
      const validEmail = this.$refs.email.validate()

      if (validType && validDescription && validFile && validFirstName && validLastName && validOrganization && validEmail) {
        if (this.loading) return
        this.$log.debug('Support#onSubmit OK')
        this.loading = true
        let payload = {
          type: this.type,
          description: this.description,
          firstName: this.firstName,
          lastName: this.lastName,
          organization: this.organization,
          email: this.email,
        }
        payload.file = null

        try {
          if (this.files.length) {
            const { data } = await alert.uploadFileForSuppport(this.files[0])
            if (data.result_code === 'FIRE-S0000') {
              if (this.files.length) {
                payload.file = process.env.VUE_APP_CDN_URL + data.file_path
              }
              await salesforce.contactSupportForm(payload)
              // await salesforce.contactSupportFormToInbound(payload)
              // this.successSubmit = true
              this.navToSupportFormSuccessPage()
            } else {
              this.$log.error(data.result_code)
            }
          } else {
            await salesforce.contactSupportForm(payload)
            // await salesforce.contactSupportFormToInbound(payload)
            // this.successSubmit = true
            this.navToSupportFormSuccessPage()
          }
        } catch (e) {
          this.$log.error(e)
        }
        this.loading = false
      }
    },
    navToSupportFormSuccessPage() {
      this.$router.push('/support-submit-success')
    },
  },
}
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  height: 100%;
  overflow: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  height: 0;
  min-height: 100%;
  overflow: auto;
}

.support-text-link {
  &.dark {
    color: #ffffffde;
  }
}

.support-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  margin-top: 100px;
  align-self: center;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

form {
  width: 752px;
  align-self: center;
  font-weight: 600;
  font-size: 18px;

  label {
    min-width: 236px;
    height: 44px;
    line-height: 44px;
  }

  .asterisk {
    color: var(--f-primary-50);
  }
}

.form-row {
  display: flex;
}
</style>
