export default {
  id: [(v) => !!v || 'ID is required.'],
  password: [(v) => !!v || 'Password is required.'],
  email: [
    (value) => !!value || 'Required.',
    (value) => (value || '').length <= 20 || 'Max 20 characters',
    (value) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Invalid e-mail.'
    },
  ],
  justEmail: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value)
  },
  emailTest: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  number: /[0-9]/,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  uppercaseGlobal: /[A-Z]/g,
  regxSpecial: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  regxSpecialGlobal: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g,
}
