<template>
  <div :class="`sucess-container ${themeName}`">
    <div class="success-title">Contact our support team</div>
    <div class="success-subtitle">
      Are you wondering about the features?<br />Check out the
      <a href="http://docs.firescout.ai/user-manual/user-manual-for-pc" target="_blank">user manual</a> or
      <a href="http://docs.firescout.ai/faq/frequently-asked-questions" target="_blank">FAQ</a> - the answer might be there already!
      <div style="margin-top: 20px">
        We are also waiting for any
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdZhJPETyUP-BT0RCs3vXGenQ5OXA4pU77OY2UajgbBhf1ZsA/viewform?usp=sf_link" target="_blank"
          >feedbacks</a
        >
        from you about FireScout!
      </div>
    </div>
    <img v-if="$vuetify.theme.dark" src="@/assets/RequestSuccess_Area.svg" width="480px" style="margin-bottom: 299px" />
    <img v-else src="@/assets/RequestSuccess_Area-light.svg" width="480px" style="margin-bottom: 299px" />
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
}
</script>

<style lang="scss" scoped>
.sucess-container {
  text-align: center;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.success-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  margin: 100px 0 30px;
}

.success-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 120px;
}
</style>
